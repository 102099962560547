<template>
  <div class="merchprdconfig">
    <div class="row">
      <div id="content" class="col-sm-12">
        <div class="page-login">
          <div class="account-border">
            <div class="row" v-if="showSpinSubmit">
              <div class="sk-chase">
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
              </div>
            </div>
            <div class="row" style="width: 100%;">
              <form action="#" method="post" enctype="multipart/form-data">
                <div class="col-sm-12 customer-login">
                  <div v-if="this.product_active_status === 2 && this.product_active_status === 2 ">
                    <h4 style="color: red;">Rejected Product</h4>
                    <h5  style="color: red;">Reject Reson : {{product_reject_reason}}</h5>
                    <div style="color: red;" v-html="product_reject_reason_description"></div>
                  </div>
                  <h2>Product Configure</h2>
                  <h4 style="color: red;">Please Note : Before start onboarding products, upload the relevant product images to Gallery by <b style="color: red;"><a @click="goToGallary()" style="color: red; margin-bottom: 20px;">click here</a></b></h4>
                  <div class="well">
                    <div class="form-group required" id="productname">
                      <label class="control-label">Product Name</label>
                      <input
                        v-model="productname"
                        type="text"
                        placeholder="Enter Product Name"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group" id="productname">
                      <label class="control-label">Made in Sri Lanka</label>&nbsp;&nbsp;
                      <input type="checkbox" v-model="madein_srilanka" value="1" name="shipping_address"/>
                    </div>

                    <div class="container testimonial-group form-group" style="margin-left: 15px;">
                      <div class="row">
                        <label for="input-country" class="control-label">Category</label>
                      </div>
                      <div class="row">
                        <div class="col-xs-3">
                          <ProductCategorySelect
                            v-bind:data="mainCategory"
                            v-bind:level="parseInt(0)"
                          />
                        </div>
                        <div
                          class="col-xs-3"
                          v-for="(item, index) in this.SeletedCategoryID"
                          :key="index"
                        >
                          <ProductCategorySelect
                            v-bind:data="item"
                            v-bind:level="parseInt(index + 1)"
                          />
                        </div>
                      </div>
                    </div>


                  <br />
                  <div class="well" v-if="this.elementHide === false">
                    <h4>
                      SKU INFORMATION
                      <hr />
                    </h4>
                    <div v-for="(item, index) in skuMainArray" :key="index">
                      <SkuGen v-bind:itemindex="index" v-bind:keydata="skuMainArray[index]" v-bind:keyArray="skuMainArray"/>
                    </div>
                    <div class="row" v-if="skuMainArray[0].skuattriarray.length > 0">
                      <div class="col-sm-6 col-md-6">
                        <button
                          type="button"
                          @click="addSku()"
                          :disabled="addskudisable"
                          class="btn btn-sm merchprof-sbtn pull-left"
                        >
                          Add New &nbsp;&nbsp;<li class="fa fa-plus"></li>
                        </button>
                      </div>
                    </div>
                    <div class="row" style="color: red;">
                      <span>* Image Size must be 1000x1000.</span><br/>
                      <span>* Keep a 100px space on either sides and ensure the product is centered.</span><br/>
                      <span>* Please note that, incase the image is removed from the given URL path, the image will not be displayed</span>
                  </div>
                  </div>
                  <br />

                    <div
                      id="productbrand"
                      class="form-group required"
                      v-if="this.elementHide === false"
                    >
                      <!-- <label class="control-label">Brand</label>
                      <b-form-input v-model="selectbrandtext" list="my-list-id"></b-form-input>

                      <datalist id="my-list-id">
                        <option
                          v-for="(item, index) in brands"
                          :key="index"
                          :data-value="item._id"
                        >{{ item.brand_name }}</option>
                      </datalist> -->

                      <!-- <label class="control-label">Brand</label>
                          <select class="form-control" v-model="selectbrand" name="country_id">
                            <option v-for="(item, index) in this.brands" :key="index" :value="item._id">{{item.brand_name}}</option>                       
                      </select>-->

                        <label class="control-label">Brand</label>
                            <select class="form-control select-arrow" v-model="selectbrandtext" >
                                <option
                                  v-for="(item, index) in brands"
                                  :key="index"
                                  :data-value="item._id"
                                >{{ item.brand_name }}</option>
                            </select>
                        </div>

                    <!-- <div
                      id="productselecteddisputeperiod"
                      class="form-"
                      v-if="this.elementHide === false"
                    >
                      <label class="control-label">Made in Sri Lanka</label>
                      <select class="form-control select-arrow" v-model="madein_srilanka">
                        <option
                          v-for="(item, index) in madeInSriLankaObj"
                          :key="index"
                          :value="item.value"
                        >{{ item.text }}</option>
                      </select>
                    </div> -->

                    <div
                      id="productmodel"
                      class="form-group required"
                      v-if="this.elementHide === false"
                      title="Use the model of the product or the product name"
                    >
                      <label class="control-label">Model</label>
                      <input
                        type="text"
                        v-model="productmodel"
                        placeholder="Model"
                        class="form-control"
                      />
                    </div>
                    
                    <div id="productshortdesc" class="form-group" v-if="this.elementHide === false">
                      <!-- <label class="control-label">Short Description</label>
                      <textarea
                        row="10"
                        v-model="shortdescription"
                        type="text"
                        placeholder="Short Description"
                        class="form-control"
                      /> -->
                    </div>
                    <div
                      id="productshortdesc1"
                      class="form-group required"
                      v-if="this.elementHide === false"
                    >
                      <label class="control-label">Overview Description</label>
                      <div id="summernote">No Information.</div>
                    </div>

                    <!-- <div id="paymentoptions" class="form-group required" v-if="this.elementHide === false">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="control-label" for="input-name">Payment Options</label>
                          </div>
                          <div class="col-sm-10">
                            <div class="row">
                                <input type="checkbox" v-model="payment_options" value="2" name="shipping_address">&nbsp; Cash &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="checkbox" v-model="payment_options" value="3" name="shipping_address">&nbsp; Card &nbsp;&nbsp;&nbsp;&nbsp;					    		    
                            </div>
                          </div>
                        </div>
                    </div>-->

                    
                    <div id="productwarrantyperiod" class="row" v-if="this.elementHide === false">
                          
                        
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="input-country" class="control-label">Warranty Types</label>
                          <select
                            class="form-control select-arrow"
                            v-model="selectedwarranty"
                          >
                            <option
                              v-for="(item, index) in warranty_types"
                              :key="index"
                              :value="item._id"
                            >{{ item.warranty_type }}</option>
                          </select>
                        </div>
                      </div>

                      <div v-if="selectedwarranty !=='61076e28bbcc7437708cb2b3'" class="col-sm-12">
                        <div class="form-group required">
                          <label for="input-country" class="control-label">Warranty Period</label>
                          <select
                            class="form-control select-arrow"
                            v-model="selectedwarrantyperiod"
                            @change="haddleWrantyChange($event.target.value)"
                          >
                            <option
                              v-for="(item, index) in warrantyperiodlist"
                              :key="index"
                              :value="item._id"
                            >{{ item.warranty_period }}</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div
                      id="warrantydescription"
                      class="form-group"
                      v-if="
                        this.elementHide === false &&
                          this.selectedwarrantyperiod !=
                            '5dc403618450273df9021b6b'
                      "
                    >
                      <label class="control-label">Warranty Description</label>
                      <div id="summernotewarranty"></div>
                    </div>

                    <div
                      id="productselecteddisputeperiod"
                      class="form-"
                      v-if="this.elementHide === false"
                    >
                      <label class="control-label">Return Policy</label>
                      <select class="form-control select-arrow" v-model="selecteddisputeperiod">
                        <option
                          v-for="(item, index) in disputeperiodlist"
                          :key="index"
                          :value="item._id"
                        >{{ item.return_type }}</option>
                      </select>
                    </div>

                    <div class="form-group" v-if="this.elementHide === false">
                      <div class="row">
                        <div class="col-sm-2">
                          <label class="control-label" for="input-name">Dangerous Goods</label>
                        </div>
                        <div class="col-sm-10">
                          <div class="row">
                            <input
                              type="checkbox"
                              v-model="dangerous_goods"
                              value="1"
                              name="shipping_address"
                            />&nbsp; Battery &nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              type="checkbox"
                              v-model="dangerous_goods"
                              value="2"
                              name="shipping_address"
                            />&nbsp; Liquid &nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              type="checkbox"
                              v-model="dangerous_goods"
                              value="3"
                              name="shipping_address"
                            />&nbsp; Flammable &nbsp;&nbsp;&nbsp;&nbsp;
                            <!-- <input
                              type="checkbox"
                              v-model="dangerous_goods"
                              value="4"
                              name="shipping_address"
                            />&nbsp; None &nbsp;&nbsp;&nbsp;&nbsp; -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="productwitp" class="form-group" v-if="this.elementHide === false">
                      <label class="control-label">What's in the package</label>
                      <input
                        type="text"
                        v-model="witp"
                        placeholder="Package Content"
                        class="form-control"
                      />
                    </div>

                    <div id="productweight" class="row" v-if="this.elementHide === false">
                      <div class="col-sm-4">
                        <div class="form-group required">
                          <h4>Package Weight</h4>
                          <label class="control-label">Actual Weight - kg</label>
                          <input
                            type="text"
                            v-model="packageweight"
                            placeholder="Actual Weight"
                            class="form-control"
                            v-on:keypress="isNumber(event)"
                          />
                         </div>
                      </div>
                    </div>
                    <div id="productweight" class="form-group" v-if="this.elementHide === false">
                      <h5>Volumetric Weight</h5>
                      <div class="row">
                        <div class="col-md-4 form-group  required">
                          <label class="control-label">Width - cm</label>
                          <input type="number" v-model="packagedimentions_width" placeholder="Width" class="form-control" min="1"
                            value="1"
                            oninput="this.value = Math.round(this.value);"/>
                        </div>
                        <div class="col-md-4 form-group  required">
                          <label class="control-label">Height - cm</label>
                          <input type="number" v-model="packagedimentions_height" placeholder="Height" class="form-control" min="1"
                            value="1"
                            oninput="this.value = Math.round(this.value);"/>
                        </div>
                        <div class="col-md-4 form-group  required">
                          <label class="control-label">Length - cm</label>
                          <input type="number" v-model="packagedimentions_length" placeholder="Length" class="form-control" min="1"
                            value="1"
                            oninput="this.value = Math.round(this.value);"/>
                        </div>
                      </div> 
                    </div>

                    <div class="form-group" v-if="this.elementHide === false">
                      <label class="control-label">Video URL</label>
                      <input
                        type="text"
                        v-model="videourl"
                        placeholder="Video URL"
                        class="form-control"
                      />
                    </div>
                    <div id="productweight" class="row" v-if="this.elementHide === false">
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="control-label">Minimum Item</label>
                          <input
                            type="number"
                            v-model="min_limit"
                            placeholder="Minimum Item"
                            min="1"
                            value="1"
                            oninput="this.value = Math.round(this.value);"
                            class="form-control"
                            v-on:change="removrHashIndex(min_limit)"
                            title="Bare minimum amount customer must buy in order to make a purchase"
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="control-label">Maximum Item</label>
                          <input
                            type="number"
                            v-model="max_limit"
                            placeholder="Maximum item"
                            min="1"
                            class="form-control"
                            value="1"
                            oninput="this.value = Math.round(this.value);"
                            title="Maximum amount customer is allowed to purchase in an instance"
                            data-pale
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label class="control-label">Minimum Stock Limit</label>
                          <input
                            type="number"
                            v-model="minimumStockLimit"
                            placeholder="Minimum stock limit"
                            min="1"
                            value="1"
                            oninput="this.value = Math.round(this.value);"
                            class="form-control"
                            title="At which stock level you should be reminded by SMS to restock your product Quantity"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6"></div>
                    </div>
                  </div>
                  <br />

                  <!-- <div class="well" v-if="this.elementHide === false">
                    <h4>
                      Key Information
                      <hr />
                    </h4>
                    <div>
                      <KeyinfoGen v-bind:keydata="this.keys" />
                    </div>
                  </div>
                  <br /> -->

                  <!-- <div id="selecteddeliverypartner" class="well" style="min-height: auto;" v-if="this.elementHide === false">
                    <h4>
                      Delivery Information
                      <hr />
                    </h4> -->

                    <!-- <div class="row">
                      <div class="col-sm-2" style="display: flex; align-items: center;"> -->
                        <!-- <ul class="product-options clearfix">
                          <li class="radio active">
                            <label class="radiocontainer">
                              <input
                                type="radio"
                                value="1"
                                @click="changePaymentType($event.target.value)"
                              />
                              <div class="check-background align-div" v-bind:class="classObject">
                                <img src="@/assets/image/icon/delivery_truck.png" />
                              </div>
                            </label>
                          </li>
                        </ul> -->

                        <!-- <ul class="product-options clearfix" style="margin-left:20px;">
                          <li class="radio active">
                            <label class="radiocontainer">
                              <input
                                type="radio"
                                value="2"
                                @click="changePaymentType($event.target.value)"
                              />
                              <div class="check-background align-div" v-bind:class="classObject1">
                                <img src="@/assets/image/icon/store_pickup.png" />
                              </div>
                            </label>
                          </li>
                        </ul> -->
                      <!-- </div>
                    </div> -->

                    <!-- <div class="row">
                      <div class="col-sm-12" style="display: flex; align-items: center;">
                        <div class="form-group" style="margin-right: 4%;">
                          <input
                            class="ml-5"
                            type="checkbox"
                            v-model="thired_party"
                            @change="enableThiredParty(thired_party)"
                            name="Thired Party Delivery"
                            :disabled="this.delivaryActiveStatus !=='Active'"
                          />&nbsp; Third Party Delivery
                        </div>
                        <div class="form-group">
                          <input
                            class="ml-5"
                            type="checkbox"
                            v-model="my_own"
                            @change="enableMyOwn(my_own)"
                            name="My Own Delivery"
                            :disabled="this.delivaryActiveStatus !=='Active'"
                          />&nbsp; My Own Delivery
                        </div>
                      </div>
                    </div> -->
                    <!-- <div v-if="thired_party" class="form-group required">
                    <label class="control-label">Delivery Partner</label>
                    <select
                      class="form-control select-arrow"
                      v-model="selecteddeliverypartner"
                      @change="
                        haddleWrantyChange($event.target.value, 'deliveryInfo')
                      "
                    >
                      <option
                        v-for="(item, index) in deliverpartners"
                        :key="index"
                        :value="item._id"
                      >{{ item.first_name + " " + item.last_name }}</option>
                    </select>
                    </div> -->

                    <!-- <div v-if="my_own && is_delivary">
                      <h4 style="color: red;" v-if="this.delivaryActiveStatus !== 'Active'">Your Delivery Account Now on {{delivaryActiveStatus}} Status</h4>
                      <span>Please Visit Delivery Portal to Setup Delivery Charges</span>&nbsp;&nbsp;&nbsp;&nbsp;<button @click="visitDelivary()" class="btn btn-primary" >Visit Delivery Portal</button>
                    </div> -->

                    <!-- <div v-if="my_own && is_create_delivary" style="display: flex; align-items: center; flex-direction: column; background-color: #e6e4e4; padding: 20px;">
                      <div class="form-group">
                        <input
                          class="ml-5"
                          type="checkbox"
                          v-model="agree_terms_Delivary"
                          name="My Own Delivery"
                        />&nbsp; By Creating Delivery Account, I Agree to the Cochchi.lk User Terms & Conditions and Privacy Policy 
                      </div>
                      <button :disabled="!agree_terms_Delivary" @click="createDelivary()"  class="btn btn-primary" >Create My Delivery Profile</button>
                    </div> -->

                    <!-- <br /> -->

                    <!-- <div class="row" v-if="!deliveryPaymentType">
                      <div class="col-sm-2">
                        <lable style="margin-right: 18px;">Handover points</lable>
                      </div>
                      <div class="col-sm-10">
                        <a
                          data-toggle="modal"
                          data-target="#handOverPoints"
                          style="color: blue; font-weight: 600;"
                        >View Details</a>
                      </div>
                    </div> -->

                    <!-- <div class="form-group">
                      <div class="row">
                        <div class="col-sm-2">
                          <label style="margin-right: 18px;">Shipping Info</label>
                        </div>
                        <div class="col-sm-10">
                          <input
                            class="ml-5"
                            type="checkbox"
                            v-model="is_free_shipping"
                            value="1"
                            name="free shipping"
                          />&nbsp; Enable Free Shipping
                        </div>
                      </div>
                    </div> -->
                  <!-- </div>
                  <br /> -->
                  <!-- <div class="well" style="min-height: auto;" v-if="this.elementHide === false">
                      <h4>Payment Information <hr></h4>
                      <div class="form-group">
                         <div class="row">
                          <div class="col-sm-2">
                            <label style="margin-right: 18px;">Card Payment</label>
                          </div>
                          <div class="col-sm-10">
                            <input class="ml-5" type="checkbox" value="1" name="free shipping" v-model="payment_option_card" disabled>&nbsp; Supiri Pola Credit
                          </div>
                        </div>
                      </div>
                  </div>-->
                  <br />
                  <div class="well" v-if="this.elementHide === false">
                    <div class="row">
                      <div class="col-sm-6 col-md-6">
                        <h2>SKU Information</h2>
                      </div>
                      <!-- <div class="col-sm-6 col-md-6">
                        <button type="button" class="btn btn-sm btn-primary pull-right">Add New</button>                     
                      </div>-->
                    </div>
                    <div class="row" id="skutable_1" style="overflow-x: hidden;">
                      <div class="col-sm-12" style="overflow-x: scroll;">
                        <table
                          id="example"
                          style="overflow-y: auto; min-width: 1200px;"
                          v-if="skuMainArray[0].skuattriarray.length > 0"
                          class="table table-striped table-bordered"
                        >
                          <thead>
                            <tr>
                              <th scope="col" style="min-width: 200px;">SKU</th>
                              <th scope="col">Quantity</th>
                              <th
                                v-for="(item, index) in this.attributes"
                                :key="index"
                                scope="col"
                              >{{ item.attribute_name }}</th>
                              <th scope="col">Listing price per unit</th>
                              <th scope="col">Selling price per unit</th>
                              <th scope="col">Primary</th>
                              <!-- <th scope="col">Markup</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(column, index) in skuMainArray" :key="index">
                              <td>
                                <input
                                  v-model="column.sku"
                                  class="form-control"
                                  type="text"
                                  value
                                  :disabled="column.active_status === 2"
                                />
                              </td>
                              <td>
                                <input
                                  v-model="column.quantity"
                                  class="form-control"
                                  type="number"
                                  value="1"
                                  min="1"
                                  oninput="this.value = Math.round(this.value);"
                                  :disabled="column.active_status === 2"
                                />
                              </td>
                              <td v-for="(item, index2) in column.skuattriarray" :key="index2">
                                {{
                                getValueSelected(item.values, item.userinput)
                                }}
                              </td>
                              <td>
                                <input
                                  v-model="column.price"
                                  class="form-control"
                                  type="number"
                                  :disabled="column.active_status === 2"
                                  min="0"
                                  oninput="this.value = Math.round(this.value);"
                                  @input="
                                    validateListingPrice(
                                      column.price,
                                      column.special_price,
                                      index
                                    )
                                  "
                                />
                              </td>
                              <td>
                                <input
                                  v-model="column.special_price"
                                  class="form-control"
                                  type="number"
                                  oninput="this.value = Math.round(this.value);"
                                  :disabled="column.active_status === 2"
                                  min="0"
                                  @input="
                                    validateSellingPrice(
                                      column.price,
                                      column.special_price,
                                      index
                                    )
                                  "
                                />
                              </td>
                              <td class="text-center">
                                <input
                                  v-model="sku_primaryIndex"
                                  class
                                  type="radio"
                                  name="skuprimary"
                                  :value="index"
                                  @change="uncheckOthers()"
                                  :disabled="column.active_status === 2"
                                />
                              </td>
                              <!-- <td class="productconfig-action-style">
                                  <div class="col-md-12" style="padding: 0;">
                                    <popper trigger="click" :options="{placement: 'bottom'}">
                                      <div class="popper" style="margin: 0px; padding: 0px; width: 300px;">
                                        <span v-if="column.loading">
                                          <img src="https://media1.tenor.com/images/2df94b2cbc32b465b9ec02451f6ffcd9/tenor.gif?itemid=8696160">
                                        </span>
                                        <div v-if="!column.loading">
                                          <div style="background:#005baa;">
                                            <h4 style="padding: 0; margin: 0; line-height: 40px; color:white;">Cochchi Fee</h4>
                                          </div>
                                          <span style="font-size: smaller; color: black;">Cochchi Fee (This fee will be deducted in your payout for IPG, SMS and other charges) :</span><br/>
                                          <span style="font-weight: 800; color: blue;">Rs. {{ parseFloat(column.special_price * (discount/100)).toFixed(2) }}</span>
                                        </div>
                                      </div>
                                      <button :disabled="(column.price > 0  && column.special_price > 0) ? false : true" style="width:100%; margin: 2px;" @click="checkDeliveryPrice(column.special_price, index);" type="button" slot="reference" class="btn btn-sm btn-info">Check Markup</button>
                                    </popper>
                                  </div>
                              </td> -->
                              <!-- <td class="productconfig-action-style">
                                  <div class="col-md-6" style="padding: 0;">
                                     <button style="width:100%; margin: 2px;" type="button" @click="scrollTo(index, 'sku_');" class="btn btn-sm btn-primary" :disabled="column.active_status === 2" >Edit</button>&nbsp;&nbsp;
                                  </div>
                                  <div class="col-md-6" style="padding: 0; padding-left: 2px;" v-if="column.active_status === 1">
                                    <button style="width:100%; margin: 2px;" type="button" @click="removeSkuByIndex(index);" class="btn btn-sm btn-danger">Deactive</button>  
                                  </div>
                                  <div class="col-md-6" style="padding: 0; padding-left: 2px;" v-if="column.active_status === 2">
                                    <button style="width:100%; margin: 2px;" type="button" @click="removeSkuByIndexActive(index);" class="btn btn-sm btn btn-success">Active</button>  
                                  </div>
                                  <div class="col-md-12" style="padding: 0;">
                                    <popper trigger="click" :options="{placement: 'bottom'}">
                                      <div class="popper" style="margin: 0px; padding: 0px; width: 300px;">
                                        <span v-if="column.loading">
                                          <img src="https://media1.tenor.com/images/2df94b2cbc32b465b9ec02451f6ffcd9/tenor.gif?itemid=8696160">
                                        </span>
                                        <div v-if="!column.loading">
                                          <div style="background:#005baa;">
                                            <h4 style="padding: 0; margin: 0; line-height: 40px; color:white;">CHECK PRICE</h4>
                                          </div>
                                          <span v-if="column.noinfo != ''">{{column.noinfo}}</span>
                                          <table v-if="column.noinfo == ''" style="width: 100%; text-align:left;">
                                            <tr>
                                              <td style="padding: 10px;">Display Price</td>
                                              <td style="color:#085499; font-size: 18px; font-weight:bold;">Rs. {{column.special_price}}</td>  
                                            </tr>
                                            <tr>
                                              <td style="padding: 10px;">Delivery Deduction <br> 
                                                <small>(if free delivery enable)</small>
                                              </td>
                                              <td v-if="is_free_shipping">Rs. 0.00</td> 
                                              <td v-else>Rs. {{column.totaldelivery}}</td>   
                                            </tr>
                                            <tr>
                                              <td style="padding: 10px;">Amount to receive <br>
                                              <small>(platform fee included)</small>
                                              </td>
                                              <td>Rs. {{  (column.special_price - (column.totalprice - column.special_price)).toFixed(2) }}</td>  
                                            </tr>
                                          </table>
                                        </div>
                                      </div>
                                      <button :disabled="(column.price > 0  && column.special_price > 0) ? false : true" style="width:100%; margin: 2px;" @click="checkDeliveryPrice(column.special_price, index);" type="button" slot="reference" class="btn btn-sm btn-info">Check Price</button>
                                    </popper>
                                  </div>
                              </td>-->
                            </tr>
                          </tbody>
                        </table>
                        <div v-if="!validListSellPrices" style="font-size: 11px; color: red;">
                          Note:- Selling price should be equal or lower than to listing
                          price
                        </div>

                        <div style="font-size: 14px; color: red;" v-if="downUrl && downUrl !== ''">
                          Note that Cochchi.lk will add the platform margins based on the product categories. 
                          Product Category Level Margins can be viewed here.
                          <span><a style="font-size: 14px; color: blue; text-decoration: underline;" @click="haddleDownLink()">(Product Category Margin Document)</a></span>
                        </div>

                      </div>
                    </div>
                  </div>
                  <br />
                  <div
                    class="row pull-right"
                    style="padding-bottom: 10px;"
                    v-if="this.elementHide === false && !this.isSubmitting"
                  >
                    <div class="col-sm-12">
                      <button
                        type="button"
                        @click="pushCancel()"
                        class="btn btn-sm btn-secondary merchprof-sbtn"
                      >Cancel</button>&nbsp;&nbsp;&nbsp;
                      <button
                        type="button"
                        @click="postProductData('new')"
                        v-if="pid === '0'"
                        :disabled="!formIsValid"
                        class="btn btn-sm merchprof-sbtn"
                      >Submit</button>
                      <button
                        type="button"
                        @click="postProductData('update')"
                        v-if="pid != '0'"
                        :disabled="!formIsValid"
                        class="btn btn-sm merchprof-sbtn"
                      >Update</button>
                    </div>
                  </div>
                  <div
                    class="row pull-right"
                    style="padding-bottom: 10px;"
                    v-if="this.elementHide === false && this.isSubmitting"
                  >
                    <div class="col-sm-12">
                      <button
                        type="button"
                        v-if="pid === '0'"
                        disabled
                        class="btn btn-sm merchprof-sbtn"
                      >Loading.....</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="handOverPoints"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLongTitle">Handover points</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" style="position: absolute; right: 17px; top: 15px;">&times;</span>
            </button>
          </div>

          <div class="modal-body container">
            <div v-for="(item, index) in addressArray" :key="index">
              <div class="row">
                <div class="col">
                  <label class="control-label">Point name</label>
                  <input
                    type="text"
                    placeholder="Point name"
                    v-model="item.name"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label class="control-label">Address</label>
                  <input
                    type="text"
                    placeholder="address"
                    v-model="item.address"
                    class="form-control"
                  />
                </div>
              </div>
              <br />
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinCategory from "@/mixins/merchant/APICategory";
import mixinProduct from "@/mixins/merchant/APIProduct";
import mixinProfile from "@/mixins/merchant/APIProfile";
import Domain from "@/mixins/domain";
import SkuGen from "@/components/Merchant/Product/SkuGen";
import KeyinfoGen from "@/components/Merchant/Product/KeyinfoGen";
import ProductCategorySelect from "@/components/Merchant/Product/ProductCategorySelect";
import { functions } from "firebase";

import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";

export default {
  components: {
    popper: Popper,
    SkuGen,
    KeyinfoGen,
    ProductCategorySelect
  },
  mixins: [mixinCategory, mixinProduct, Domain, mixinProfile],
  data: function() {
    return {
      breadcrumb: [
        {
          text: "Product Overview",
          href: "/product-store",
          active: false
        },
        {
          text: "Product Configure",
          active: true
        }
      ],
      delivaryActiveStatus: "",
      paymentTypeValue: 1,
      pickup_addresses: [],
      deliveryPaymentType: true,
      thired_party: true,
      my_own: false,
      is_delivary: false,
      is_free_shipping: false,
      is_create_delivary: false,
      agree_terms_Delivary: false,
      viewDis: false,
      showSpinSubmit: false,
      saveDisable: false,
      addskudisable: true,
      isSubmitting: false,
      product_active_status: null,
      product_admin_status: null,
      product_reject_reason: "",
      product_reject_reason_description: "",
      product_id: "",
      productname: "",
      productmodel: "",
      dangerous_goods: [],
      madein_srilanka: false,
      payment_option: 1,
      payment_option_card: true,
      payment_option_cod: true,
      shortdescription: "",
      selecteddisputeperiod: "No Return",
      // madein_srilanka: false,
      witp: "",
      packageweight: "",
      packagedimentions_length: "",
      packagedimentions_width: "",
      packagedimentions_height: "",
      videourl: "",
      min_limit: 1,
      max_limit: 1,
      minimumStockLimit: 1,
      storeListData: [],
      mainCategory: [],
      SelectedFinalCategory: "",
      SeletedCategoryID: [],
      SeletedCategoryIDStore: [],
      child: true,
      elementHide: true,
      assignedData: [],
      brands: [],
      attributes: [],
      keys: [],
      warranty_types: [],
      selectbrand: "",
      selectbrandtext: "",
      selectedwarranty: "",
      warrantyperiodlist: [],
      disputeperiodlist: [
        {
          value: "No Return",
          text: "No Return"
        },
        {
          value: "7 Days",
          text: "7 Days Return"
        },
        {
          value: "14 Days",
          text: "14 Days Return"
        }
      ],
      // madeInSriLankaObj: [
      //   {
      //     value: true,
      //     text: "Yes"
      //   },
      //   {
      //     value: false,
      //     text: "No"
      //   }
      // ],
      addressArray: [],
      selectedwarrantyperiod: "",
      selecteddeliverypartner: "",
      skuMainArray: [
        {
          skuattriarray: [],
          imgarray: []
        }
      ],
      sku_primaryIndex: 0,
      productdata: {
        categories: [],
        store: "5d9af3bd9e47057677ccdaa7",
        category: "",
        product_name: "product1",
        brand: "5d84d37f66e5095764af10fa",
        service: "5d9b02d6cc3a997ac1772abf",
        model: "A5",
        highlight: "sss",
        description: "sss",
        warranty_type: "5d93352d570adc5007bdb5df",
        warranty_period: "5d9b0323cc3a997ac1772ac1",
        dangerous_goods: [1, 2],
        warranty_policy: "policy1",
        warranty_policy_en: "policy1",
        english_description: "englis1",
        english_name: "englis1",
        english_highlight: "englis1",
        what_is_in_box: "englis1",
        package_weight: 2.3,
        package_dimension: "1.5,5,2",
        video_url: "dadad",
        keys: [],
        simple_products: [],
        payment_option: 1,
        product_type: 2
      },
      brandsug: ["Small", "Medium", "Large", "Extra Large"],
      deliverpartners: [],
      validListSellPrices: false,
      skuListingPrice: 0,
      skuSellingPrice: 0,
      discount: 0,
      downUrl: "",
      basicInfo:{}
    };
  },
  watch: {
    assignedData: function(val) {
      this.updateViewByAssigned();
    },
    SelectedFinalCategory: function(val) {
      this.validateCategories();
    },
    skuMainArray: function(val) {
      this.uncheckOthers();
      this.validateImageCount();
    }
  },
  mounted() {
    $(document).ready(function() {
      $('[data-toggle="popover"]').popover();
      $("#summernote").summernote({ height: 200 });
      $("#summernotewarranty").summernote({ height: 200 });
    });
  },
  created() {
    this.handleBreadcrumb();
    this.getCategories();
    this.getCatXLDownlord();
    this.getWarrantyPeriodData();
    this.setDelivaryPartner();
    this.getDisputePeriodData();
    this.getProductByIdCheck();
    this.checkListSellPrices();
    this.saveDisable = false;
  },
  computed: {
    classObject: function() {
      if (this.paymentTypeValue == 1) {
        return "cash-icons-active";
      } else {
        return "cash-icons-deactive";
      }
    },
    classObject1: function() {
      if (this.paymentTypeValue == 2) {
        return "cash-icons-active";
      } else {
        return "cash-icons-deactive";
      }
    },
    pid() {
      let url = window.location.href.split("merchant-product-configure/");
      return url[1];
    },
    findSKUvalueById: function(values, selectedID) {
      for (let index = 0; index < values.length; index++) {
        if (values[index]._id === selectedID) {
          return values[index].value;
        } else {
          return "";
        }
      }
    },
    formIsValid() {
      return this.validListSellPrices == true;
    }
  },
  methods: {
    setTwoNumberDecimal: function (event) {
        this.packageweight = parseFloat(this.packageweight).toFixed(2);
    },
    checkListSellPrices: function() {
      if (this.skuListingPrice == 0 && this.skuSellingPrice == 0) {
        this.validListSellPrices = true;
      } else {
        this.validateListingPrice();
      }
    },
    setDelivaryPartner: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        this.basicInfo = response;
        this.selecteddeliverypartner = this.basicInfo.deliver;
      } catch (error) {
        throw error;
      }
    },
    validateListingPrice: function(listPrice, sellingPrice, arrayIndex) {
      this.skuListingPrice = parseInt(listPrice);
      this.skuSellingPrice = parseInt(sellingPrice);

      this.skuMainArray[arrayIndex].special_price = listPrice;

      this.validListSellPrices = false;

      this.skuSellingPrice = this.skuListingPrice;

      if (this.skuListingPrice >= this.skuSellingPrice) {
        this.validListSellPrices = true;
      } else {
        this.validListSellPrices = false;
      }
    },
    validateSellingPrice: function(listPrice, sellingPrice, arrayIndex) {
      this.skuListingPrice = parseInt(listPrice);
      this.skuSellingPrice = parseInt(sellingPrice);

      this.validListSellPrices = false;

      if (this.skuListingPrice >= this.skuSellingPrice) {
        this.validListSellPrices = true;
      } else {
        this.validListSellPrices = false;
      }
    },
    changePaymentType: function(val) {
      this.paymentTypeValue = val;
      if (val === "1") {
        this.deliveryPaymentType = true;
      } else if (val === "2") {
        this.deliveryPaymentType = false;
      }
    },
    haddleCheckPaymentOption: function(index) {
      if (index === true) {
        this.payment_option = 1;
      } else if (index === false) {
        this.payment_option = 2;
      }
    },
    checkDeliveryPrice: async function(product_price, index) {
      this.$set(this.skuMainArray[index], "totalprice", "");
      this.$set(this.skuMainArray[index], "totaldelivery", "");
      this.$set(this.skuMainArray[index], "noinfo", "");
      this.$set(this.skuMainArray[index], "loading", true);

      let data = {
        delivery_partner: this.selecteddeliverypartner,
        product_price: product_price
        // "length": this.packagedimentions_length,
        // "width": this.packagedimentions_width,
        // "height": this.packagedimentions_height
      };
      let response = await this.getDeliveryProductPrice(data);

      if (response.hasOwnProperty("totProdctPrice")) {
        this.$set(this.skuMainArray[index], "noinfo", "");
        this.$set(
          this.skuMainArray[index],
          "totalprice",
          response.totProdctPrice
        );
        this.$set(
          this.skuMainArray[index],
          "totaldelivery",
          response.totDeliveryPrice
        );
        this.$set(this.skuMainArray[index], "loading", false);
      } else {
        this.$set(this.skuMainArray[index], "loading", false);
        this.$set(this.skuMainArray[index], "noinfo", "No Information");
      }
    },
    changeWarrrantyType: function(type) {
      if (type === "5f683169be246e147fbafd80" || type === "61076e28bbcc7437708cb2b3") {
        this.isWarranty_type = false;
      } else {
        this.isWarranty_type = true;
      }
      
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getCategories: async function() {
      try {
        let status = await this.getMainCategories();
        this.mainCategory = status;
      } catch (error) {
        console.log(error);
      }
    },
    getCatXLDownlord: async function() {
      try {
        let url = await this.getXLDownlord();
        this.downUrl = Domain.APP_DOMAIN.split("api/v1/")[0] + url.url;
      } catch (error) {
        console.log(error);
      }
    },
    haddleDownLink: function() {
      window.open(this.downUrl);
    },
    getSubCategories: async function(id) {
      try {
        let status = await this.getSubCategoryById(id);
        return status;
      } catch (error) {
        console.log(error);
      }
    },
    getCategoryassigns: async function(id) {
      try {
        let status = await this.getCategoryassignsByID(id);
        $("#summernote").summernote({ height: 150 });
        $("#summernotewarranty").summernote({ height: 200 });
        return status.result;
      } catch (error) {
        console.log(error);
      }
    },
    getWarrantyPeriodData: async function() {
      try {
        let status = await this.getWarrantyPeriods();
        if (status.length > 0) {
          this.warrantyperiodlist = status;
          this.selectedwarrantyperiod = this.warrantyperiodlist[0]._id;
        }
      } catch (error) {
        console.log(error);
      }
    },
    getDisputePeriodData: async function() {
      try {
        let response = await this.getDisputePeriod();
        this.disputeperiodlist = response;
        this.selecteddisputeperiod = response[0]._id;
      } catch (error) {
        console.log(error);
      }
    },
    updateMenuLevel: async function(level, catid, have_child) {
      this.child = have_child;

      this.skuMainArray = [
        {
          skuattriarray: [],
          imgarray: []
        }
      ];

      let size = parseInt(this.SeletedCategoryID.length);
      this.SeletedCategoryID.splice(level, size);
      this.SeletedCategoryIDStore.splice(level, size);

      const value = await this.getSubCategories(catid);

      this.SeletedCategoryID.splice(level, 1, value);
      this.SeletedCategoryIDStore.splice(level, 1, catid);

      this.SelectedFinalCategory = catid;

      if (this.child === false) {
        this.assignedData = await this.getCategoryassigns(catid);
      } else {
        this.assignedData = [];
        this.SelectedFinalCategory = "";
      }
    },
    updateDiscount(obj) {
      this.discount = obj.discount;
    },
    updateViewByAssigned() {
      if (this.assignedData.hasOwnProperty("keys")) {
        this.brands = this.assignedData.brands;
        if (this.brands.length > 0) {
          this.selectbrand = this.brands[0]._id;
        }

        this.attributes = this.assignedData.attributes;
        //this.skuMainArray = [];
        this.skuMainArray[0].skuattriarray = JSON.parse(
          JSON.stringify(this.attributes)
        );
        this.skuMainArray[0].price = 0;
        this.skuMainArray[0].quantity = 0;
        this.skuMainArray[0].price = 0;
        this.skuMainArray[0].special_price = 0;
        this.skuMainArray[0].is_primary = true;
        // this.skuMainArray[0].sku = this.productname;
        this.skuMainArray[0].sku = this.productname.replace(/ /g, "_") + "_" + parseInt(1);
        this.skuMainArray[0].imgarray = [""];

        this.keys = this.assignedData.keys;

        this.warranty_types = this.assignedData.warranty_types;

        if (this.warranty_types.length > 0) {
          this.selectedwarranty = this.warranty_types[0]._id;
        } else {
          this.selectedwarranty = "";
        }
      } else {
        this.brands = [];
        this.attributes = [];
        this.keys = [];
        this.warranty_types = [];

        this.selectedwarranty = "";
      }
    },
    addSku() {
      this.skuMainArray.push({
        skuattriarray: JSON.parse(JSON.stringify(this.attributes)),
        price: 0,
        quantity: 0,
        price: 0,
        special_price: 0,
        is_primary: false,
        sku: this.productname.replace(/ /g, "_") + "_" + parseInt(this.skuMainArray.length + 1),
        imgarray: [""]
      });
    },
    removeSkuByIndex(skuIndex) {
      this.skuMainArray.splice(skuIndex, 1);
      if (this.skuMainArray.length > 1) {
        this.skuMainArray[skuIndex].active_status = 2;
        this.sku_primaryIndex = 0;
      }

      this.uncheckOthers();
    },
    removeSkuByIndexActive(skuIndex) {
      if (this.skuMainArray.length > 1) {
        this.skuMainArray[skuIndex].active_status = 1;
        this.sku_primaryIndex = 0;
      }

      this.uncheckOthers();
    },
    scrollTo(index, section) {
      document.getElementById(section + index).scrollIntoView();
    },
    getValueSelected: function(values, selectedID) {
      for (let index = 0; index < values.length; index++) {
        if (values[index]._id === selectedID) {
          return values[index].value;
        }
      }
    },
    uncheckOthers() {
      for (let index = 0; index < this.skuMainArray.length; index++) {
        if (index === this.sku_primaryIndex) {
          this.$set(this.skuMainArray[index], "is_primary", true);
        } else {
          this.$set(this.skuMainArray[index], "is_primary", false);
        }
      }
    },
    validateImageCount() {
      this.addskudisable = true;
      for (let index = 0; index < this.skuMainArray.length; index++) {
        for (
          let indeximg = 0;
          indeximg < this.skuMainArray[index].imgarray.length;
          indeximg++
        ) {
          if (this.skuMainArray[index].imgarray[indeximg] != "") {
            this.addskudisable = false;
          }
        }
      }
    },
    validateWarnning(index, section) {
      let self = this;
      this.isSubmitting = false;
      this.$swal.fire({
        backdrop: false,
        position: "center",
        type: "error",
        title: "Please Fill The Required Fields!",
        showConfirmButton: false,
        timer: 1500,
        onAfterClose: () => self.scrollTo(index, section)
      });
    },
    postProductData: async function(mode = "new") {
      this.isSubmitting = true;
      let validated = false;
      this.saveDisable = true;
      let self = this;
      var selectedBrandArray = this.brands.filter(function(el) {
        var brand_name = self.selectbrandtext.toLowerCase();
        var resbrand_name = el.brand_name.toLowerCase();
        return brand_name === resbrand_name;
      });

      if (selectedBrandArray.length > 0) {
        this.selectbrand = selectedBrandArray[0]._id;
      } else {
        if (
          this.selectbrandtext === "" ||
          typeof this.selectbrandtext === "undefined"
        ) {
          this.validateWarnning("", "productbrand");
          return;
        }

        let brandObj = {
          brand_name: this.selectbrandtext,
          category_id: this.SelectedFinalCategory
        };

        try {
          let res = await this.postNewMerchantBrand(brandObj);
          this.selectbrand = res._id;
        } catch (error) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: error.message,
            showConfirmButton: false,
            timer: 1500
          });
          this.saveDisable = false;
          this.isSubmitting = false;
          return;
        }
      }

      if (this.payment_option_card && this.payment_option_cod) {
        this.payment_option = 1;
      } else {
        this.payment_option = 2;
      }

      if (this.selectedwarrantyperiod === "5dc403618450273df9021b6b") {
        this.productdata.product_name = this.productname;
        this.productdata.madein_srilanka = this.madein_srilanka;
        this.productdata.categories = this.SeletedCategoryIDStore;
        this.productdata.brand = this.selectbrand;
        this.productdata.model = this.productmodel;
        this.productdata.dangerous_goods = this.dangerous_goods;
        this.productdata.warranty_type = this.selectedwarranty;
        this.productdata.warranty_period = this.selectedwarrantyperiod;
        this.productdata.dispute_period = this.selecteddisputeperiod;
        // this.productdata.madein_srilanka = this.madein_srilanka;
        this.productdata.what_is_in_box = this.witp;
        this.productdata.package_weight = this.packageweight;
        this.productdata.video_url = this.videourl;
        this.productdata.category = this.SelectedFinalCategory;
        this.productdata.highlight = this.shortdescription;
        this.productdata.description = $("#summernote").summernote("code");
        if (this.packagedimentions_length !=="" && this.packagedimentions_width !=="" && this.packagedimentions_height !=="") {
          this.productdata.package_dimension = this.packagedimentions_length +','+this.packagedimentions_width+','+this.packagedimentions_height;
        } else {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Please Enter Volumetric Weight Values",
            showConfirmButton: false,
            timer: 1500
          });
          this.isSubmitting = false;
        }
        this.productdata.simple_products = [];
        this.productdata.keys = [];
        this.productdata.store = this.$store.state.merchant_selectedstore;
        this.productdata.deliver = this.selecteddeliverypartner;
        this.productdata.own_delivrey = this.my_own;
        this.productdata.is_free_shipping = this.is_free_shipping;
        this.productdata.payment_option = this.payment_option;
        this.productdata.min_limit = this.min_limit;
        this.productdata.max_limit = this.max_limit;
        this.productdata.minimun_stock_limit = this.minimumStockLimit;
      } else {
        this.productdata.minimun_stock_limit = this.minimumStockLimit;
        this.productdata.min_limit = this.min_limit;
        this.productdata.max_limit = this.max_limit;
        this.productdata.product_name = this.productname;
        this.productdata.madein_srilanka = this.madein_srilanka;
        this.productdata.categories = this.SeletedCategoryIDStore;
        this.productdata.brand = this.selectbrand;
        this.productdata.model = this.productmodel;
        this.productdata.own_delivrey = this.my_own;
        this.productdata.dangerous_goods = this.dangerous_goods;
        this.productdata.warranty_type = this.selectedwarranty;
        if (this.selectedwarranty !=="5f683169be246e147fbafd80") {
          this.productdata.warranty_period = this.selectedwarrantyperiod;
        }
        this.productdata.dispute_period = this.selecteddisputeperiod;
        // this.productdata.madein_srilanka = this.madein_srilanka;
        this.productdata.what_is_in_box = this.witp;
        this.productdata.package_weight = this.packageweight;
        this.productdata.video_url = this.videourl;
        this.productdata.category = this.SelectedFinalCategory;
        this.productdata.highlight = this.shortdescription;
        this.productdata.description = $("#summernote").summernote("code");
        this.productdata.warranty_description = $(
          "#summernotewarranty"
        ).summernote("code");
        if (this.packagedimentions_length !=="" && this.packagedimentions_width !=="" && this.packagedimentions_height !=="") {
          this.productdata.package_dimension = this.packagedimentions_length +','+this.packagedimentions_width+','+this.packagedimentions_height;
        } else {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Please Enter Volumetric Weight Values",
            showConfirmButton: false,
            timer: 1500
          });
          this.isSubmitting = false;
        }
        this.productdata.simple_products = [];
        this.productdata.keys = [];
        this.productdata.store = this.$store.state.merchant_selectedstore;
        this.productdata.deliver = this.selecteddeliverypartner;
        this.productdata.is_free_shipping = this.is_free_shipping;
        this.productdata.payment_option = this.payment_option;
      }

      if (mode === "update") {
        this.productdata._id = this.product_id;
      }

      if (
        this.$store.state.merchant_selectedstore === "" ||
        this.$store.state.merchant_selectedstore === "-1" ||
        typeof this.$store.state.merchant_selectedstore === "undefined"
      ) {
        this.$swal.fire({
          backdrop: false,
          position: "center",
          type: "error",
          title: "Please Select A Store!",
          showConfirmButton: false,
          timer: 1000,
          onAfterClose: () => $(window).scrollTop(0)
        });
        this.saveDisable = false;
        this.isSubmitting = false;
        return;
      }

      // if(this.payment_options.length === 0){
      //   this.validateWarnning('', 'paymentoptions');
      //   return;
      // }
      if (this.productname === "" || typeof this.productname === "undefined") {
        this.validateWarnning("", "productname");
        return;
      }
      if (
        this.selectbrandtext === "" ||
        typeof this.selectbrandtext === "undefined"
      ) {
        this.validateWarnning("", "productbrand");
        return;
      }
      if (
        this.productmodel === "" ||
        typeof this.productmodel === "undefined"
      ) {
        this.validateWarnning("", "productmodel");
        return;
      }
      // if(this.shortdescription === "" || typeof this.shortdescription === "undefined"){
      //   this.validateWarnning('', 'productshortdesc');
      //   return;
      // }
      // if(this.selectedwarrantyperiod === "" || typeof this.selectedwarrantyperiod === "undefined"){
      //   this.validateWarnning('', 'productwarrantyperiod');
      //   return;
      // }
      // if(this.selecteddisputeperiod === "" || typeof this.selecteddisputeperiod === "undefined"){
      //   this.validateWarnning('', 'productselecteddisputeperiod');
      //   return;
      // }
      // if(this.witp === "" || typeof this.witp === "undefined"){
      //   this.validateWarnning('', 'productwitp');
      //   return;
      // }
      if (
        this.packageweight === "" ||
        typeof this.packageweight === "undefined"
      ) {
        this.validateWarnning("", "productweight");
        return;
      }

      if (
        this.selecteddeliverypartner === "" ||
        typeof this.selecteddeliverypartner === "undefined"
      ) {
        this.validateWarnning("", "selecteddeliverypartner");
        return;
      }
      
      if(this.packagedimentions_length === "" || typeof this.packagedimentions_length === "undefined"){
        this.validateWarnning('', 'productweight');
        return;
      }
      if(this.packagedimentions_width === "" || typeof this.packagedimentions_width === "undefined"){
        this.validateWarnning('', 'productweight');
        return;
      }
      if(this.packagedimentions_height === "" || typeof this.packagedimentions_height === "undefined"){
        this.validateWarnning('', 'productweight');
        return;
      }

      for (let index = 0; index < this.skuMainArray.length; index++) {
        let attributes = [];
        let skuattri = this.skuMainArray[index].skuattriarray;
        for (let index = 0; index < skuattri.length; index++) {
          attributes.push({
            attribute_id: skuattri[index]._id,
            attribute_value_id: skuattri[index].userinput
          });
        }

        if (
          this.skuMainArray[index].quantity === 0 ||
          this.skuMainArray[index].quantity === "" ||
          typeof this.skuMainArray[index].quantity === "undefined"
        ) {
          this.validateWarnning(1, "skutable_");
          return;
        }
        if (
          this.skuMainArray[index].special_price === 0 ||
          this.skuMainArray[index].special_price === "" ||
          typeof this.skuMainArray[index].special_price === "undefined"
        ) {
          this.validateWarnning(1, "skutable_");
          return;
        }

        if (
          this.skuMainArray[index].price === "" ||
          typeof this.skuMainArray[index].price === "undefined"
        ) {
          this.validateWarnning(1, "skutable_");
          return;
        }
        let self = this;
        if (this.addskudisable === true) {
          this.$swal.fire({
            backdrop: false,
            position: "center",
            type: "error",
            title: "Please Add At Least One Image to SKU!",
            showConfirmButton: false,
            timer: 2000,
            onAfterClose: () => self.scrollTo(0, "sku_")
          });
          this.saveDisable = false;
          this.isSubmitting = false;
          return;
        }

        // REMOVE EMPTY IMAGES
        var filtered = this.skuMainArray[index].imgarray.filter(function(el) {
          return el != "";
        });

        this.skuMainArray[index].imgarray = filtered;

        if (this.skuMainArray[index].imgarray.length === 0) {
          // this.skuMainArray[index].imgarray.splice(0,1)
          this.skuMainArray[index].imgarray.push(
            "http://res.cloudinary.com/persystance-network/image/upload/v1583924174/slt/portal_user/1583924174301.png"
          );
        }

        let simple_product = {
          is_primary: this.skuMainArray[index].is_primary,
          sku: this.skuMainArray[index].sku,
          quantity: this.skuMainArray[index].quantity,
          price: this.skuMainArray[index].price,
          special_price: this.skuMainArray[index].special_price,
          description: "sskks",
          free_item: "1",
          start_promotion_date: "2018-09-09",
          end_promotion_date: "2019-09-09",
          attributes: attributes,
          images: this.skuMainArray[index].imgarray,
          active_status: this.skuMainArray[index].active_status
        };

        if (mode === "update") {
          simple_product._id = this.skuMainArray[index]._id;
        }

        this.productdata.simple_products.push(simple_product);
      }
      // PREPAREING KEY ARRAY
      for (let index = 0; index < this.keys.length; index++) {
        if (
          ["radio", "checkbox", "dropdown"].includes(this.keys[index].key_type)
        ) {
          this.productdata.keys.push({
            key_id: this.keys[index].key,
            key_value_id: this.keys[index].userinput,
            key_value_text: ""
          });
        } else {
          // if (
          //   this.keys[index].userinput === "" ||
          //   typeof this.keys[index].userinput === "undefined"
          // ) {
          //   this.productdata.keys = [];
          //   // this.validateWarnning(this.keys[index].key, "key_");
          //   // return;
          // }

          this.productdata.keys = [];
        }
      }

      // if(this.payment_options.length === 2){
      //   this.productdata.payment_option = 1
      // }

      // if(this.payment_options.length === 1){
      //   this.productdata.payment_option = this.payment_options[0]
      // }

      this.productdata.catagory_discount = this.discount;
      console.log("this.productdata")
      console.log(this.productdata)
      try {
        if (mode === "update") {
          let status = await this.putProductCreate(this.productdata);
          this.saveDisable = true;

          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Product Successfully Updated!",
            showConfirmButton: false,
            timer: 1500
          });
          this.isSubmitting = false;
        } else {
          let status = await this.postProductCreate(this.productdata);
          this.saveDisable = true;
          this.showSpinSubmit= true;
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Product Successfully Submitted!",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.showSpinSubmit = false;
        this.$router.push("/product-store").catch(err => {});
      } catch (error) {
        console.log(error);
        this.isSubmitting = false;
      }
    },
    validateCategories() {
      if (this.SelectedFinalCategory != "") {
        this.elementHide = false;
      } else {
        this.elementHide = true;
      }
    },
    pushCancel() {
      this.$router.push("/product-store");
    },
    getProductByIdCheck: async function() {
      if (this.pid !== "0") {
        try {

          let response = await this.getProductByIdWithStore(
            this.pid,
            this.$store.state.merchant_selectedstore
          );

          this.productname = response.product_name;
          this.madein_srilanka = response.madein_srilanka;
          this.product_id = response._id;
          this.product_active_status = response.active_status;
          this.product_admin_status = response.admin_status;
          let responseCategories = response.categories;

          for (let index = 0; index < responseCategories.length; index++) {
            let child = true;
            if (index === responseCategories.length - 1) {
              child = false;
            }

            await this.updateMenuLevel(index, responseCategories[index], child);
            var element = document.getElementById(
              this.SeletedCategoryIDStore[index]
            );
            if (element !== null) {
              element.classList.add("cactive");
            }
          }

          this.selectbrand = "";
          for (let index = 0; index < this.brands.length; index++) {
            if (this.brands[index]._id === response.brand._id) {
              this.selectbrandtext = this.brands[index].brand_name;
            }
          }

          this.selectbrand = response.brand;
          this.productmodel = response.model;
          this.dangerous_goods = response.dangerous_goods;
          this.selectedwarranty = response.warranty_type;
          this.selectedwarrantyperiod = response.warranty_period;
          this.selecteddisputeperiod = response.dispute_period;
          this.witp = response.what_is_in_box;
          this.my_own = response.own_delivrey;
          this.packageweight = response.package_weight;
          if (response.package_dimension) {
            this.packagedimentions_length = parseInt(response.package_dimension.split(',')[0]);
            this.packagedimentions_width = parseInt(response.package_dimension.split(',')[1]);
            this.packagedimentions_height = parseInt(response.package_dimension.split(',')[2]);
          }
          this.videourl = response.video_url;
          this.min_limit = response.min_limit;
          this.max_limit = response.max_limit;
          this.minimumStockLimit = response.minimun_stock_limit;
          this.SelectedFinalCategory = response.category;
          this.shortdescription = response.highlight;
          if (response.own_delivrey) {
            this.my_own = true;
            this.thired_party = false;
            this.is_delivary = true;
          }
          this.selecteddeliverypartner =
            response.deliver._id != undefined ? response.deliver._id : "";
          this.deliveryPaymentType =
            response.deliver.pickup != undefined ? response.deliver.pickup : "";
          if (this.deliveryPaymentType === true) {
            this.paymentTypeValue = 1;
          } else if (this.deliveryPaymentType === false) {
            this.paymentTypeValue = 2;
          }
          if (response.reject_reason) {
            this.product_reject_reason = response.reject_reason;
          }

          if (response.reject_reason_description) {
            this.product_reject_reason_description = response.reject_reason_description;
          }
          $("#summernote").summernote("code", response.description);
          $("#summernotewarranty").summernote(
            "code",
            response.warranty_description
          );
          this.is_free_shipping = response.is_free_shipping;
          this.payment_option = response.payment_option;

          if (this.payment_option == 1) {
            this.payment_option_cod = true;
            this.payment_option_card = true;
          } else {
            this.payment_option_cod = false;
            this.payment_option_card = true;
          }

          // if(response.payment_option){
          //   switch (response.payment_option) {
          //     case 1:
          //       this.payment_options = []
          //       this.payment_options = [2,3]
          //       break;

          //     case 2:
          //       this.payment_options = []
          //       this.payment_options = [2]
          //       break;

          //     case 3:
          //       this.payment_options = []
          //       this.payment_options = [3]
          //       break;

          //     default:
          //       this.payment_options = [2]
          //       break;
          //   }
          // }
          // if (response.package_dimension !== undefined) {
          //   var split_dimension = response.package_dimension.split(',');
          //   this.packagedimentions_length = split_dimension[0];
          //   this.packagedimentions_width = split_dimension[1];
          //   this.packagedimentions_height = split_dimension[2];
          // }

          for (let indexkey = 0; indexkey < this.keys.length; indexkey++) {
            for (
              let indexreskey = 0;
              indexreskey < response.keys.length;
              indexreskey++
            ) {
              if (
                this.keys[indexkey].key === response.keys[indexreskey].key_id
              ) {
                if (response.keys[indexreskey].key_value_id) {
                  this.$set(
                    this.keys[indexkey],
                    "userinput",
                    response.keys[indexreskey].key_value_id
                  );
                  this.$set(
                    this.keys[indexkey],
                    "edituserinput",
                    response.keys[indexreskey].key_value_id
                  );
                } else {
                  this.$set(
                    this.keys[indexkey],
                    "userinput",
                    response.keys[indexreskey].key_value_text
                  );
                }
              }
            }
          }

          let skuBaseObject = JSON.stringify(this.skuMainArray[0]);

          this.skuMainArray = [];

          for (
            let indexsmp = 0;
            indexsmp < response.simple_products.length;
            indexsmp++
          ) {
            this.skuMainArray.push(JSON.parse(skuBaseObject));

            this.$set(
              this.skuMainArray[indexsmp],
              "is_primary",
              response.simple_products[indexsmp].is_primary
            );
            this.$set(
              this.skuMainArray[indexsmp],
              "price",
              response.simple_products[indexsmp].price
            );
            this.$set(
              this.skuMainArray[indexsmp],
              "special_price",
              response.simple_products[indexsmp].special_price
            );
            this.$set(
              this.skuMainArray[indexsmp],
              "quantity",
              response.simple_products[indexsmp].quantity
            );
            this.$set(
              this.skuMainArray[indexsmp],
              "sku",
              response.simple_products[indexsmp].sku
            );
            this.$set(
              this.skuMainArray[indexsmp],
              "_id",
              response.simple_products[indexsmp]._id
            );
            this.$set(
              this.skuMainArray[indexsmp],
              "active_status",
              response.simple_products[indexsmp].active_status
            );

            for (
              let indexresattri = 0;
              indexresattri <
              response.simple_products[indexsmp].attributes.length;
              indexresattri++
            ) {
              this.$set(
                this.skuMainArray[indexsmp].skuattriarray[indexresattri],
                "userinput",
                response.simple_products[indexsmp].attributes[indexresattri]
                  .attribute_value_id
              );
              this.$set(
                this.skuMainArray[indexsmp].skuattriarray[indexresattri],
                "edituserinput",
                response.simple_products[indexsmp].attributes[indexresattri]
                  .attribute_value_id
              );
            }

            this.skuMainArray[indexsmp].imgarray = [];

            for (
              let indexresimgs = 0;
              indexresimgs < response.simple_products[indexsmp].images.length;
              indexresimgs++
            ) {
              this.skuMainArray[indexsmp].imgarray.push(
                response.simple_products[indexsmp].images[indexresimgs].image
              );
            }
          }

          // mainCategory
          // calc discount
          let obj = this.mainCategory.filter(el => {
            return el._id == response.categories[0];
          });
          this.discount = obj[0].discount;

        } catch (error) {
          console.log(error);
        }
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    goToGallary: function(){
      this.$router.push("/gallery-list")
    },
    removrHashIndex: function(value) {
      // if(e) e.preventDefault();
      // alert(value)
    }
  }
};
</script>
<style>
.select-arrow {
  -webkit-appearance: menulist;
  box-sizing: border-box;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  color: #555;
  background-color: white;
  cursor: default;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
}

.productconfig-action-style {
  width: 180px;
}

/* The heart of the matter */
.testimonial-group > .row {
  overflow-x: auto;
  white-space: nowrap;
}
.testimonial-group > .row > .col-xs-3 {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  float: none;
}
.cash-icons-active {
  color: white !important;
  background-color: #d5d5d5;
}
.cash-icons-deactive {
  color: black !important;
  background-color: white;
}
.icons-active {
  color: white !important;
}
.icons-deactive {
  color: black !important;
}
.cactive {
  background-color: #d7d7d7;
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow-y: visible !important;
}

/* Decorations */
.radiocontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: relative;
  top: 0;
  left: 0;
  /* height: 25px; */
  /* width: 25px; */
  padding: 8px 14px;
  border-radius: 5%;
  margin-right: 10px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radiocontainer input:checked ~ .checkmark:after {
  display: block;
}

.radiocontainer .checkmark:after {
  top: 10px;
  left: 8px;
  /* width: 20px;
height: 20px; */
  border-radius: 5%;
  background: transparent;
}

/* ****************************** */

.check-background {
  position: relative;
  border-radius: 5%;
  border: 1px solid #428bff;
  /* background-color: white; */
  color: black !important;
  /* padding: 8px 10px;
margin-right:10px; */
}

.check-background:after {
  content: "";
  position: absolute;
  display: none;
}
.align-div {
  width: 70px;
  height: 70px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}
.radiocontainer input:checked ~ .check-background:after {
  display: block;
}

.radiocontainer .check-background:after {
  top: 0px;
  left: 0px;
  /* width: 100%;
height: 100%; */
  border-radius: 5%;
  /* background-color: rgb(0, 91, 170); color: rgb(255, 255, 255); */
  color: white !important;
  border: 1px solid #666666;
}
.merchprdconfig .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.merchprdconfig .merchprof-sbtn:hover {
  color: #000000;
}
</style>
